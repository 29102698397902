/* Following CSS are only in the demo and are of no use in a Production */

.show-grid [class*="col-"] {
    background: @border-lighter; text-align: center; margin-bottom: 10px; line-height: 2em; border:10px solid @tone-brighter;
    .content {background: @border-lighter; border: 1px solid @border-darker}
}
.show-grid [class*="col-"]:hover {background: @border-darker; }

.show-grid .row.no-gutter [class*="col-"] {
    border-left: 0;
    border-right: 0;
}


li.show-grid {
    .row {
        margin: 0;
        &:last-child {
            [class*="col-"] {
                margin: 0;
            }
        }
    }
    [class*="col-"] {
        border-bottom: 0;
        border-top: 0;
    }
}

.navbar-inverse .show-grid [class*="col-"] {
    background: #333; border-color:#4d4d4d;
}

.demo-example .breadcrumb {margin-bottom: 20px;}

#demo-type-table {
	width:100%;
	margin: -20px 0 -20px 0;
	td {vertical-align: middle;}
	h1,h2,h3,h4,h5,h6 {margin: 15px 0;}
	td:last-child {text-align: right;}
}

.demo-options {
    z-index: 900; 
    position: fixed;
    top: 144px;
	width: 260px;
    border: 1px solid @border-darker;
    border-right: 0 !important;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.07);
	padding: 0;
    border-bottom-left-radius: @border-radius-base;
    right: -260px;
    z-index: @zindex-navbar + 40;
    
    background: #fff;

    .transition(~"transform 0.15s ease");

    &.active{
        transform: translateX(-260px);
        -ms-transform: translateX(-260px);
        -o-transform: translateX(-260px);
        -webkit-transform: translateX(-260px);

    }

    .demo-heading {
        padding: 11px 0px;
        font-size: @font-size-small;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: @gray;
        background: @panel-default-heading-bg;
        border-bottom: 1px solid @border-lighter;
    }

    .demo-options-icon {
        display: inline-block;
        position: absolute;
        left: -43px;
        padding: 10px 12px;
        font-size: @font-size-base;
        border: 1px solid @border-darker;
        border-right: 0 !important;
        letter-spacing: normal;
        text-transform: none;
        background: @panel-default-heading-bg;
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: @border-radius-base;
        top: -1px;
        cursor: pointer;
        
        box-shadow: -1px 2px 1px rgba(0, 0, 0, 0.07);
    }

    .demo-body {
        padding: 15px 20px;
        border-bottom: 1px solid @border-lighter;
        &:last-child {border-bottom: 0;}
        .tabular {
            margin-bottom: 0;
            .tabular-cell {
                height: 30px;
                font-size: @font-size-small;
                &.demo-switches {text-align: right;}
            }
        }
        .option-title {
            font-size: @font-size-small;
            margin-bottom: 10px;
        }
    }

    .demo-color-list {
        padding: 0;
        text-align: left;
        margin: 0;
        li {
            padding-left: 0;
            padding-right: 0;
            span {
                border-radius: 10px; 
                display: inline-block;
            }
        }
        li, li span {
            height: 20px;
            width: 20px;
            //margin-bottom: -5px;
            display: inline-block;
            &:hover {cursor: pointer;}
        }

        .demo-white {background-color: @brand-default; border: 1px solid darken(@brand-default,2%);}
        .demo-black {background-color: @gray-darker; border: 1px solid darken(@gray-darker,2%);}
        .demo-grape {background-color: @brand-grape; border: 1px solid darken(@brand-grape,2%);}
        .demo-primary {background-color: @brand-primary; border: 1px solid darken(@brand-primary,2%);}
        .demo-green {background-color: @brand-green; border: 1px solid darken(@brand-green,2%);}
        .demo-alizarin {background-color: @brand-alizarin; border: 1px solid darken(@brand-alizarin,2%);}
        .demo-danger {background-color: @brand-danger; border: 1px solid darken(@brand-danger,2%);}
        .demo-midnightblue {background-color: @brand-midnightblue; border: 1px solid darken(@brand-midnightblue,2%);}
        .demo-indigo {background-color: @brand-indigo; border: 1px solid darken(@brand-indigo,2%);}
        .demo-violet {background-color: @brand-violet; border: 1px solid darken(@brand-violet,2%);}
        .demo-info {background-color: @brand-info; border: 1px solid darken(@brand-info,2%);}


        .pattern-brickwall         {background: url(../img/patterns/brickwall.png)}
        .pattern-dark-stripes      {background: url(../img/patterns/dark_stripes.png)}
        .pattern-rockywall         {background: url(../img/patterns/rockywall.png)}
        .pattern-subtle-carbon     {background: url(../img/patterns/subtle_carbon.png)}
        .pattern-tweed             {background: url(../img/patterns/tweed.png)}
        .pattern-vertical-cloth    {background: url(../img/patterns/vertical_cloth.png)}
        .pattern-grey_wash_wall    {background: url(../img/patterns/grey_wash_wall.png)}
        .pattern-pw_maze_black     {background: url(../img/patterns/pw_maze_black.png)}
        .pattern-wild_oliva        {background: url(../img/patterns/wild_oliva.png)}
        .pattern-stressed_linen    {background: url(../img/patterns/stressed_linen.png)}
        .pattern-sos               {background: url(../img/patterns/sos.png)}

        [class*="pattern-"] {
            border: 1px solid @border-darker;
        }

    }
}



.sidebar .widget .demo-grid .row > div {
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    background: #121;
    box-shadow: inset 0px 0px 1px 0px #fff;
}


// Boxed Layout backgrounds

.demo-blocks {
    width: 50px;height: 50px; border: 2px solid @border-lighter;
    display: inline-block; cursor: pointer;
    margin-right: 20px; margin-bottom: 10px;
    &:hover {
        border-color: @border-darker;
    }

    &.color-dark        {background:#313338;}
    &.color-red         {background:#b43e2e;}
    &.color-green       {background:#48d09b;}
    &.color-blue        {background:#2e5dac;}
    &.color-lavender    {background:#716885;}

    &.pattern-brickwall         {background: url(../img/patterns/brickwall.png)}
    &.pattern-dark-stripes      {background: url(../img/patterns/dark_stripes.png)}
    &.pattern-rockywall         {background: url(../img/patterns/rockywall.png)}
    &.pattern-subtle-carbon     {background: url(../img/patterns/subtle_carbon.png)}
    &.pattern-tweed             {background: url(../img/patterns/tweed.png)}
    &.pattern-vertical-cloth    {background: url(../img/patterns/vertical_cloth.png)}
    &.pattern-grey_wash_wall    {background: url(../img/patterns/grey_wash_wall.png)}
    &.pattern-pw_maze_black     {background: url(../img/patterns/pw_maze_black.png)}
    &.pattern-wild_oliva        {background: url(../img/patterns/wild_oliva.png)}
    &.pattern-stressed_linen    {background: url(../img/patterns/stressed_linen.png)}
    &.pattern-sos               {background: url(../img/patterns/sos.png)}
}



// UI-Buttons Page Demo Styles
ul.demo-btns {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
        display: inline-block;
        margin-bottom: 10px;
    }
}



.demo-dashboard-map {
    .demo-table-countries {
        table > thead > tr > th {background: transparent !important;}
        border: none;
    }
    table {margin: 0 !important;}
    .progress {
        margin: 0 !important;
        background: transparent !important;
        .progress-bar {
            border-radius: @border-radius-base;

        }
    }
    .footer-pagination {
        border-top: 2px solid @border-darker;
        padding: 10px 0 0;
        .pagination {
            margin: 0 !important;
            float: right;
        }
    }
}


.demo-new-members {
    table {.icheck {margin: 0 -20px -5px 0 !important;}}
    table td.td-btn {line-height: 1}

    .footer-pagination {
        background: @tone-bright;
        border-top: 2px solid @border-darker;
        padding: 10px;
        border-bottom-right-radius: @border-radius-base;
        .pagination {
            margin: 0 !important;
            float: right;
        }
    }
}

ul#heatmap {
    margin: 0;
    li {padding: 0 5px 0 0;}
    .gradient {
        #gradient > .horizontal(#fff, @brand-primary);
        display: inline-block;
        height: 10px;
        width: 120px;
        border-radius: @border-radius-small;
    }
}



.vertical-rule {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: @border-lighter;
    @media (max-width: @screen-md-max) {
        display: none;
    }
}

.demo-graph {
    margin: 20px 0 0;
}

.tile-piechart {
    padding: 20px 10px;
    .easypiechart {
        width: 60px;
        padding: 0px;
    }
    .tile-info {
        margin: 5px 10px 0;
    }
}


.tile-piechart-footer { // used in Dashboard
    padding: 0px 10px !important;
    overflow: hidden; // http://stackoverflow.com/questions/5272091/firefox-interprets-margin-bottom-wrong-maybe-a-bug
}

.demo-dashboard-graph {
    .tab-content {
        height: 324px;
        .tab-pane {
            display: block;
            visibility: hidden;
            &.active {
                visibility: visible;
            }
       }
   }
   .graph-1 {}
   .graph-2 {position: relative; top: -270px}
   .graph-3 {position: relative; top: -600px}
}   


.navbar-default.demo {
    border-radius: @border-radius-base;
    border: 1px solid @border-darker !important;
}

.navbar-inverse.demo {
    border-radius: @border-radius-base;
}



// form components demo icheck 

.demo-icheck {
    .radio label, .checkbox label {padding-left: 0 !important;}
    .icheck > label > div {margin-right: 5px !important;}
}


// hide search on small screen

.demo-search-hidden {
    @media (max-width: 400px) {
        .responsive-invisibility();
    }
}

.demo-headerdrop-hidden {
    @media (max-width: 400px) {
        .responsive-invisibility();
    }
}

@media (max-width: 400px) {
    .dropdown-menu.notifications {
        margin-right: -78px;
        &:before, &:after {margin-right: 78px;}
    }
}



.demo-mapael.map > svg {max-height: 189px;}


